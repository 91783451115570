import aurich from './aurich'
import beemoor from './beemoor'
import bremen from './bremen'
import dornum from './dornum'
import emden from './emden'
import esens from './esens'
import hage from './hage'
import leer from './leer'
import norden from './norden'

export default {
  aurich,
  beemoor,
  bremen,
  dornum,
  emden,
  esens,
  hage,
  leer,
  norden,
}
